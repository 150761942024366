
import minutesToHoursString from '@/helpers/minutesToHoursString'

export default {
  data() {
    return {
      showMaxAirlines: 5,
      isOpen: false,
      submitted: false,
      selectedStops: [0, 1, 2],
      selectedOutboundDuration: Infinity,
      selectedInboundDuration: Infinity,
      rejectedAirlines: [],
      isAirlinesCollapsed: true
    };
  },
  computed: {
    searchAirlines () {
      return this.$store.state.searchAirlines
    },
    searchAirlinesMain () {
      return this.searchAirlines.filter(item => item.code !== '00')
    },
    searchAirlinesMultiple () {
      return this.searchAirlines.filter(item => item.code === '00')
    },
    searchAirlinesGroups () {
      return [this.searchAirlinesMain, this.searchAirlinesMultiple]
    },
    parsedFilteredResults () {
      return this.$store.getters.parsedFilteredResults
    },
    results () {
      const results = this.$store.state.searchResults
      if (!results.length) return []
      return results
    },
    filters () {
      const OutboundDurations = this.results.map(flight => flight.legs[0]?.duration)
      const InboundDurations = this.results.map(flight => flight.legs[1]?.duration)

      const minOutboundDuration = Math.min(...OutboundDurations)
      const maxOutboundDuration = Math.max(...OutboundDurations)
      const minInboundDuration = Math.min(...InboundDurations)
      const maxInboundDuration = Math.max(...InboundDurations)

      const stops = this.results.map((flight) => flight.stops)
      const stopOptions = Array.from(new Set(stops))

      return {
        minOutboundDuration,
        maxOutboundDuration,
        minInboundDuration,
        maxInboundDuration,
        stopOptions
      }
    },
    isRoundtrip () {
      // return this.results[0].legs.length === 2
      return this.results.every(flight => flight.legs && flight.legs.length > 1)
    }
  },
  watch: {
    filters: {
      deep: true,
      handler: 'handleWatchFilters'
    }
  },
  methods: {
    minutesToHoursString,
    scrollTo (id) {
      const el = document.getElementById(id)
      if (el) el.scrollIntoView({ behavior: 'smooth' })
    },
    handleStopChange() {
      this.submitted = true
      this.$store.commit('setSearchFilters', {
        stops: this.selectedStops
      });
    },
    handleOutboundDurationChange() {
      this.submitted = true
      this.$store.commit('setSearchFilters', {
        outboundDuration: this.selectedOutboundDuration
      });
    },
    handleInboundDurationChange() {
      this.submitted = true
      this.$store.commit('setSearchFilters', {
        inboundDuration: this.selectedInboundDuration
      });
    },
    handleWatchFilters () {
      const searchFilters = this.$store.state.searchFilters
          
      this.selectedOutboundDuration = searchFilters.outboundDuration !== null ? searchFilters.outboundDuration : this.filters.maxOutboundDuration
      this.selectedInboundDuration = searchFilters.inboundDuration !== null ? searchFilters.inboundDuration : this.filters.maxInboundDuration
    },
    clearFilters () {
      this.submitted = false
      this.$store.commit('clearSearchFilters')
      this.selectedStops = [0, 1, 2]
      this.selectedOutboundDuration = this.filters.maxOutboundDuration
      this.selectedInboundDuration = this.filters.maxInboundDuration
      this.airlinesSelectAll()
    },
    toggleAirline (e) {
      const code = e.target.value
      if (e.target.checked) {
        const index = this.rejectedAirlines.indexOf(code)
        if (index !== -1) this.rejectedAirlines.splice(index, 1)
      } else {
        this.rejectedAirlines.push(code)
      }
      this.submitted = true
      this.$store.commit('setSearchFilters', {
        rejectedAirlines: this.rejectedAirlines.slice()
      })
    },
    airlinesSelectAll () {
      this.rejectedAirlines = []

      const checkboxes = this.$refs.airline

      if (Array.isArray(checkboxes)) {
        checkboxes.forEach(checkbox => {
          checkbox.checked = true
        })
      } else {
        checkboxes.checked = true
      }
    }
  }
}
