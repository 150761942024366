
import commaNumber from 'comma-number'
import analytics from '@/helpers/analytics'
import criteo from '@/helpers/criteo'

export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      maxShowPartners: 8,
      maxShowItems: 10,
      minPartnerItems: 2
    }
  },
  computed: {
    deeplinks() {
      return this.$store.state.deeplinks
    },
    minPriceFormatted () {
      return this.localCurrency ? commaNumber(this.item.minPriceLocal) : commaNumber(this.item.minPrice)
    },
    shouldShowPartnerName() {
      return this.item.cpc > 0
    },
    shouldShowPartnerList() {
      return this.item.partners && this.shouldShowPartnerName
    },
    parsedItems() {
      const parsedPartners = this.item.partners.slice(1, this.maxShowPartners).map((partnerItem) => {
        return { ...partnerItem, component: 'DropdownPartnerItem' }
      })

      const maxShowDiff = this.maxShowItems - parsedPartners.length

      const parsedCompare = this.$store.state.compareItems.slice(0, maxShowDiff).map((compareItem) => {
        return { ...compareItem, component: 'DropdownCompareItem' }
      })

      return parsedPartners.length > this.minPartnerItems ? [...parsedPartners] : [...parsedPartners, ...parsedCompare]
    },
    localCurrency () {
      return this.$store.state.localCurrency
    },
    displaySymbol () {
      return this.localCurrency ? this.item.currencySymbolLocal : this.item.currencySymbol || this.item.currency
    }
  },
  methods: {
    handleCardClick() {
      this.shouldShowPartnerName ? this.openPartnerDeeplinkUrl() : this.openCompareModal()
    },
    clickHandler() {
      return this.openPartnerDeeplinkUrl()
    },
    clickPhoneQuote() {
      analytics.track('meta-unit-phone-click', this)
      window.top.location = `tel:${this.item.phoneQuote.number}`
    },
    partnerClick (item) {
      if (item === undefined) {
        item = this.item
      }

      const partnerCpc = Number(item.cpc).toFixed(2)
      const currency = 'USD'

      // google conversion
      if (this.$gtag && this.$store.state.siteConfig.googleConversion) {
        this.$gtag.event('conversion', {
          send_to: this.$store.state.siteConfig.googleConversion,
          value: partnerCpc,
          currency
        })
      }
      // Bing conversion
      if (window.uetq) {
        window.uetq.push('event', 'OTAclickout', {
          revenue_value: partnerCpc,
          currency
        })
      }
      // Criteo product tag
      this.pushCriteoParams()
      // Adara event
      this.fireAdaraEvent(this.item)
    },
    openPartnerDeeplinkUrl() {
      // Skip triggering the event for fallback partner (cpc = 0)
      if (this.item.cpc > 0) {
        this.partnerClick()
      }
      analytics.track('pricelink-click', this)
      console.info('Open partner deeplink URL')
      window.newTab = window.open(this.item.partnerDeeplinkUrl, '_blank')
    },
    openCompareModal() {
      if (this.$store.state.isCompareToAdsModalLoaded) {
        this.$store.commit('setInlineAdDeeplink', this.item.partnerDeeplinkUrl)
        this.$emit('openCompareModal')
        return
      }

      // Fallback if compare modal is not loaded
      this.openApiDeeplinkUrls()
    },
    openApiDeeplinkUrls () {
      analytics.track('view-deals-click', this)
      console.info('Open API deeplink URLs')
      const deeplinks = this.item.apiDeeplinkUrls
      window.newTab = window.open('', '_blank')

      if (deeplinks[0]) {
        window.newTab.location.href = deeplinks[0].redirect
      }

      if (deeplinks[1]) {
        window.location.href = deeplinks[1].redirect
      }

      if (deeplinks[2] && window.popUnder) {
        window.popUnder.location.href = deeplinks[2].redirect
        window.popUnder.moveTo(0, 0)
        window.popUnder.resizeTo(screen.width, screen.height)
      }
    },
    compareAll (data) {
      this.$emit('compareAll', data)
    },
    pushCriteoParams () {
      const customerId = this.$store.state.entryId
      const currency = this.item.currency ?? 'USD'
      const id = customerId + this.item.uniqueId
      const price = this.item.cpc

      const options = { customerId, currency, price, id }
      criteo.purchaseTag(this.$store, options)
    },
    fireAdaraEvent (item) {
      if (Object.keys(item).length === 0) return

      const adaraParams = {
        travelProductType: 'Flight',
        tripType: item.legs.length === 2 ? 'roundtrip' : 'one-way',
        originAirportCode: item.legs[0].from.code,
        originCity: item.legs[0].from.city,
        // originCountry,
        // originState,
        destinationAirportCode: item.legs[1].from.code,
        destinationCity: item.legs[1].from.city,
        // destinationCountry,
        // destinationState,
        departureDate: item.legs[0].depTime.split('T')[0],
        returnDate: item.legs[1].depTime.split('T')[0],
        numPassenger: this.$store.state.passangers.adults + this.$store.state.passangers.children,
        numAdults: this.$store.state.passangers.adults,
        numChildren: this.$store.state.passangers.children,
        ticketClass: item.cabinClass,
        ticketPrice: item.minPrice,
        airlineBrand: item.legs[0].airline.name
      }
      try {
        window.adara('send', {
          pageType: 'Purchase Item',
          siteLanguage: this.$store.state.language,
          siteCountry: this.$store.state.country,
          ...adaraParams
        })
      } catch (e) {
        console.info('Error firing Adara event')
      }
    }
  }
}
