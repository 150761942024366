// plugins/sentry.js

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import Vue from 'vue'

export default (context, inject) => {

  const config = context.$config;

  const dsn = config.SENTRY_DSN
  if (!dsn) return

  const ignoreErrors = [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    "Cannot read properties of undefined (reading 'toLowerCase')",
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage'
  ];

  const denyUrls = [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
  ];

  // Initialize Sentry for the client-side
  context.$sentry = Sentry.init({
    // app: context.app,
    Vue,
    dsn: dsn,
    // integrations: [
      // new Integrations.BrowserTracing({
        // tracingOrigins: ["localhost", "yourdomain.com", /^\//],
        // routingInstrumentation: Integrations.BrowserTracing.vueRouterInstrumentation(context.app.router),
      // }),
    // ],
    config: { 
      ...config,
      environment: config.environmentName || 'production' 
    },
    ignoreErrors: ignoreErrors,
    denyUrls: denyUrls,
    tracesSampleRate: 1.0, // Adjust to your needs for production
  });

  inject('sentry', context.$sentry);

  // Global error handling
  window.onerror = function (message, source, lineno, colno, error) {
    Sentry.captureException(error || new Error(message));
  };

  window.onunhandledrejection = function (event) {
    Sentry.captureException(event.reason);
  };
};