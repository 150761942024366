
import get from 'lodash/get'
import compact from 'lodash/compact'
import omit from 'lodash/omit'
import { addDays, format, parseISO, differenceInDays } from 'date-fns'
import isFacebookInAppBrowser from 'detect-facebook-in-app-browser'
import store from 'store'
import analytics from '@/helpers/analytics'
import transformParams from '@/helpers/transformParams'
import fireFacebookEvents from '@/helpers/fireFacebookEvents'
import insertSojernTag from '@/helpers/insertSojernTag'
import replaceDeeplinksPlaceholders from '@/helpers/replaceDeeplinksPlaceholders'
import { flights as pairings } from '@/static/data/pairings.json'

function queryHasArray (query, queryStringKey) {
  return Boolean(Object.entries(query).filter(([key, value]) => {
    return key.startsWith(`${queryStringKey}[`)
  }).length)
}

export default {
  data () {
    return {
      debug: true,
      originData: {},
      originError: null,
      destinationData: {},
      destinationError: null,
      generalError: null,
      form: {
        origin: null,
        destination: null,
        originText: null,
        destinationText: null,
        outboundDate: null,
        inboundDate: null,
        adults: 1,
        children: 0,
        cabinClass: 'economy',
        preferDirects: false,
        includeHotel: false,
        p: [],
        roundtrip: true
      }
    }
  },
  computed: {
    siteType () {
      return this.$store.state.siteType
    },
    pageTitle () {
      return this.$store.state.pageTitle
    },
    deviceCategory () {
      return this.$store.getters.deviceCategory
    },
    fromPlaceholder () {
      return this.$t(`${this.siteType}.search.${this.deviceCategory}FromPlaceholder`)
    },
    toPlaceholder () {
      return this.$t(`${this.siteType}.search.${this.deviceCategory}ToPlaceholder`)
    },
    originEqualsDestinationError () {
      return this.form.roundtrip && this.form.destination && this.form.origin && (this.form.destination === this.form.origin)
    },
    showSearchResults () {

      /*
      if (this.$store.state.site != 'farescraper.com') {
        return get(this.$route.query, 'results') || this.$route.name === 'results'
      }
      */

      const { query } = this.$route
      const regex = /(adwords|bing|facebook|fb|prpl)/gi

      if (
        get(query, 'gclid') ||
        get(query, 'msclkid') ||
        queryHasArray(query, 'p') ||
        (get(query, 'utm_source') && get(query, 'utm_source').match(regex))
      ) {
        return false
      }

      return true
    }
  },
  created () {
    this.setDefaults()
  },
  methods: {
    openNewsletterModal () {
      this.$store.commit('setNewsletterModal', true)
    },
    clickRoundtrip () {
      analytics.track('roundtrip-uncheck', this)
    },
    clickFlightAndHotel () {
      analytics.track('flight-hotel-check', this)
    },
    clickPreferDirects () {
      analytics.track('direct-flight-check', this)
    },
    selectOrigin ({ value, keyword, data }) {
      this.originData = Object.assign({}, data)
      this.form.origin = value
      this.form.originText = keyword
      this.originError = null
    },
    selectDestination ({ value, keyword, data }) {
      this.destinationData = Object.assign({}, data)
      this.form.destination = value
      this.form.destinationText = keyword
      this.destinationError = null
    },
    async setDefaults () {
      const defaults = store.get('FLIGHTS_LAST_SEARCH')
      const query = this.$route.query || {}
      const relatedSearch = this.$store.state.relatedSearch

      this.form = Object.assign({}, this.form, defaults || {}, transformParams.toApi({ ...query, ...relatedSearch }, pairings))

      if (query.originId || query.destinationId) {
        const ids = compact([query.originId, query.destinationId]).join(',')
        const cities = await this.$axios.$get(`cities?ids=${ids}`)

        cities.forEach((city) => {
          if (city.id === parseInt(query.originId)) {
            this.form.origin = city.airportcode
            this.form.originText = city.text
          }

          if (city.id === parseInt(query.destinationId)) {
            this.form.destination = city.airportcode
            this.form.destinationText = city.text
          }
        })
      }

      const today = new Date()
      if (!this.form.outboundDate || parseISO(this.form.outboundDate) < today) this.form.outboundDate = format(addDays(today, 7), 'yyyy-MM-dd')
      if (!this.form.inboundDate || parseISO(this.form.inboundDate) < today) this.form.inboundDate = format(addDays(today, 14), 'yyyy-MM-dd')
    },
    setOutboundDate (value) {
      this.form.outboundDate = value
      this.$refs.outboundDate.open()
    },
    setInboundDate (value) {
      this.form.inboundDate = value
    },
    selectPassengers (data) {
      this.form[data.name] = data.count
    },
    selectCabinClass (value) {
      this.form.cabinClass = value
    },
    scrollTo (id) {
      const el = document.getElementById(id)
      if (el) el.scrollIntoView({ behavior: 'smooth' })
    },
    async search () {
      this.$store.commit('setPassangers', {
        adults: this.form.adults,
        children: this.form.children
      })

      if (this.originEqualsDestinationError) return

      this.originError = !Object.keys(this.originData).length ? this.$t('search.errors.required') : null
      this.destinationError = this.form.roundtrip && !Object.keys(this.destinationData).length ? this.$t('search.errors.required') : null
      if (this.originError || this.destinationError) return

      if (this.form.roundtrip && (parseISO(this.form.outboundDate) > parseISO(this.form.inboundDate))) {
        return this.$toast.error(this.$t('flights.search.dateError'), { position: 'top-center', duration: 4000 })
      }

      setTimeout(() => {
        this.scrollTo('results')
      }, 500)

      this.generalError = null

      analytics.track('search-submit', this, {
        ...this.form,
        siteType: this.siteType
      })

      try {
        if (!this.showSearchResults && !isFacebookInAppBrowser()) window.newTab = window.open('/loading', '_blank')
      } catch (e) {
        console.error(e)
        console.info('Can not open a new tab')
      }

      store.set('FLIGHTS_LAST_SEARCH', omit(this.form, ['p', 'gclid', 'msclkid', 'utm_source', 'utm_campaign', 'utm_term', 'roundtrip', 'preferDirects', 'includeHotel']))

      const { query } = this.$route
      const { country, site, entryId, siteType, deeplinks, browser } = this.$store.state
      const { deviceCategory } = this.$store.getters

      // GTAG
      try {
        if (this.$gtag && this.originData && this.destinationData) {
          this.$gtag.event('view_search_results', {
            send_to: this.$config.GOOGLE_REMARKETING,
            user_id: this.$store.state.entryId,
            items: [{
              origin: this.originData.airportcode,
              destination: this.destinationData.airportcode,
              start_date: this.form.outboundDate,
              end_date: this.form.inboundDate,
              flight_originid: this.originData.airportcode,
              flight_destid: this.destinationData.airportcode,
              flight_startdate: this.form.outboundDate,
              flights_enddate: this.form.inboundDate,
              flight_pagetype: 'search_results',
              travel_originid: this.originData.airportcode,
              travel_destid: this.destinationData.airportcode,
              travel_startdate: this.form.outboundDate,
              travel_enddate: this.form.inboundDate,
              travel_pagetype: 'search_results',
              dynx_itemid: this.originData.airportcode,
              dynx_itemid2: this.destinationData.airportcode,
              dynx_pagetype: 'search_results',
              google_business_vertical: 'flights'
            }]
          })
        }
      } catch (e) {
        console.error(e)
        console.info('Error firing GTAG event view_search_results')
      }

      // Facebook
      fireFacebookEvents('flight', this.form, this.originData, this.destinationData)

      // Adara
      const originCountry = this.originData.countrycode === 'US' ? this.originData.countryname.split(',')[1].trim() : this.originData.countryname
      const originState = this.originData.countrycode === 'US' ? this.originData.countryname.split(',')[0].trim() : ''
      const destinationCountry = this.destinationData.countrycode === 'US' ? this.destinationData.countryname.split(',')[1].trim() : this.destinationData.countryname
      const destinationState = this.destinationData.countrycode === 'US' ? this.destinationData.countryname.split(',')[0].trim() : ''

      try {
        const adaraParams = {
          travelProductType: 'Flight',
          tripType: this.form.roundtrip ? 'roundtrip' : 'one-way',
          originAirportCode: this.originData.airportcode,
          originCity: this.originData.cityname,
          originCountry,
          originState,
          destinationAirportCode: this.destinationData.airportcode,
          destinationCity: this.destinationData.cityname,
          destinationCountry,
          destinationState,
          departureDate: this.form.outboundDate,
          returnDate: this.form.inboundDate,
          numPassenger: this.form.adults + this.form.children,
          numAdults: this.form.adults,
          numChildren: this.form.children,
          ticketClass: this.form.cabinClass
        }

        window.adara('send', {
          pageType: 'Results Page',
          siteLanguage: this.$store.state.language,
          siteCountry: this.$store.state.country,
          ...adaraParams
        })
      } catch (e) {
        console.error(e)
        console.info('Error firing Adara event')
      }

      // Redirect to results page
      if (this.showSearchResults) {
        return this.$router.push({ name: 'results', query: transformParams.toQuery(this.form, pairings) })
      }

      // Sojern
      try {
        const sojernParams = {
          p: 'search',
          eml: this.$store.state.user.data.email,
          ccid: this.$store.state.entryId,
          tad: this.form.adults,
          tch: this.form.children,
          t: this.form.adults + this.form.children,
          ppot: 'leisure',
          l: window.navigator.language,
          vid: 'flight',
          fow: !this.form.roundtrip,
          fc: this.form.cabinClass,
          fd: this.form.roundtrip ? differenceInDays(new Date(this.form.inboundDate), new Date(this.form.outboundDate)) : null,
          pname: window.location.href,
          fc2: this.originData.cityname,
          fn2: '',
          fc1: this.destinationData.cityname,
          fn1: '',
          fd1: this.form.outboundDate,
          fd2: this.form.inboundDate,
          fa1: this.form.origin,
          fa2: this.form.destination
        }

        insertSojernTag(sojernParams, this)
      } catch (e) {
        console.error(e)
        console.info('Error when inserting the Sojern script')
      }

      try {
        const params = {
          ...this.form,
          searchDebug: this.$store.state.isDebugEnabled ? 1 : 0,
          device: deviceCategory,
          country: country || 'WW',
          site: site.replace('v2.', '').replace('new.', '').replace('ab.', '').replace('dev-ab.', ''),
          entryId,
          adid: query.gclid || query.msclkid,
          sitebrand: siteType,
          locale: this.$store.state.language
        }

        let redirects = []

        if (!deeplinks || !deeplinks.length) {
          redirects = await this.$axios.$get(this.$config.API_URL + '/flights/deeplinks', { params })
        } else {
          redirects = replaceDeeplinksPlaceholders(deeplinks, params)
        }

        if (!redirects.length) {
          return this.$toast.error('No results, please try again', { position: 'top-center', duration: 4000 })
        }

        if (window.newTab) {
          window.newTab.location.href = redirects[0].redirect
          if (redirects[1]) window.location.href = redirects[1].redirect
        } else {
          window.location.href = redirects[0].redirect
        }

        if (redirects[2] && window.popUnder && browser?.name !== 'safari') {
          window.popUnder.location.href = redirects[2].redirect
          window.popUnder.moveTo(0, 0)
          window.popUnder.resizeTo(screen.width, screen.height)
        }
      } catch (e) {
        console.error(e)
        return this.$toast.error(e.toString(), { position: 'top-center', duration: 4000 })
      }
    }
  }
}
