
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import FlightsListItem from '@/components/FlightsListItem.vue'
import KayakAdInline from '@/components/KayakAdInline.vue'
import analytics from '@/helpers/analytics'
import criteo from '@/helpers/criteo'
import first from 'lodash/first';

const parseInlineAds = (unparsed) => {
  return unparsed.map((ad, index) => ({ ...ad, id: `kayak-${index}`, component: 'KayakAdInline' }))
}

export default {
  components: {
    FlightsListItem,
    KayakAdInline
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      topInlineAds: 3,
      altarnateEach: 4,
      initialRenderResults: 30,
      page: 1
    }
  },
  computed: {
    firstDeepLink () {
      if (!this.$store.state.deeplinks.length) {
        return ''
      }
      const firstLink = this.$store.state.deeplinks.filter(item => item.provider.toLowerCase() !== 'travelmedia')

      return firstLink[0] ? firstLink[0].redirect : ''
    },
    inlineAds () {
      const ads = this.$store.state.inlineAds
      if (!ads || !ads.length) return [null, null, null]
      return parseInlineAds(ads)
    },
    parsedFilteredResults () {
      const sortingType = this.$store.state.sortingType
      return this.sortResults(this.$store.getters.parsedFilteredResults, sortingType)
    },
    items () {
      const { parsedFilteredResults, inlineAds, topInlineAds, altarnateEach } = this

      // Copy the results to avoid mutation
      const resultsCopy = [...parsedFilteredResults]
      const items = []

      // Add initial alternating pattern
      for (let i = 0; i < Math.min(resultsCopy.length, inlineAds.length); i++) {
        items.push(inlineAds[i], resultsCopy[i])
      }
      // Add remaining inlineAds if any
      if (inlineAds.length > resultsCopy.length) {
        items.push(...inlineAds.slice(resultsCopy.length))
      }

      // Add subsequent alternating pattern with top inlineAds
      // Limit top inline ads if total inline ads is less than top
      const topAds = Math.min(topInlineAds, inlineAds.length)
      let adsIndex = 0

      for (let i = inlineAds.length + altarnateEach - 2; i < resultsCopy.length; i += altarnateEach) {
        resultsCopy.splice(i, 0, inlineAds[adsIndex])
        adsIndex = adsIndex === topAds - 1 ? 0 : adsIndex + 1
      }
      items.push(...resultsCopy.slice(inlineAds.length))

      return this.limitRenderedResults(items)
    },
    hasResultItems () {
      return this.$store.state.searchResults.length > 0
    },
    hasItems () {
      return this.items && Object.values(this.items).length
    }
  },
  watch: {
    parsedFilteredResults (newVal) {
      if (newVal.length === 0) this.page = 1
    },
    hasResultItems (newVal) {
      if (newVal) {
        const firstResult = this.$store.state.searchResults[0]
        this.pushCriteoParams(firstResult)
        this.fireAdaraEvent(firstResult)
      }
    }
  },
  methods: {
    resultComponentType (item) {
      return (item.component == 'KayakAdInline') ? 'result-inline-ad' : 'search-result';
    },
    fireAdaraEvent (item) {
      if (Object.keys(item).length === 0) return

      const adaraParams = {
        travelProductType: 'Flight',
        tripType: item.legs.length === 2 ? 'roundtrip' : 'one-way',
        originAirportCode: item.legs[0].from.code,
        originCity: item.legs[0].from.city,
        // originCountry,
        // originState,
        destinationAirportCode: item.legs[1].from.code,
        destinationCity: item.legs[1].from.city,
        // destinationCountry,
        // destinationState,
        departureDate: item.legs[0].depTime.split('T')[0],
        returnDate: item.legs[1].depTime.split('T')[0],
        numPassenger: this.$store.state.passangers.adults + this.$store.state.passangers.children,
        numAdults: this.$store.state.passangers.adults,
        numChildren: this.$store.state.passangers.children,
        ticketClass: item.cabinClass,
        ticketPrice: item.minPrice,
        airlineBrand: item.legs[0].airline.name
      }
      try {
        window.adara('send', {
          pageType: 'Results Item',
          siteLanguage: this.$store.state.language,
          siteCountry: this.$store.state.country,
          ...adaraParams
        })
      } catch (e) {
        console.info('Error firing Adara event')
      }
    },
    pushCriteoParams (item = []) {
      if (Object.keys(item).length === 0) return
      const customerId = this.$store.state.entryId
      const productId = item.uniqueId.split('-')[1] + '-' + item.uniqueId.split('-')[2]
      const options = { customerId, price: item.minPrice, productId }
      criteo.productTag(this.$store, options)
    },
    showMoreResults () {
      analytics.track('meta-show-more-results-btn', this)
      this.page++
    },
    limitRenderedResults (results) {
      const limit = this.initialRenderResults * this.page
      return Array.isArray(results) ? omitBy(results.slice(0, limit), isNil) : []
    },
    handleNoResultsClick () {
      analytics.track('meta-no-results-click', this)
    },
    openCompareModal (item) {
      this.$emit('openCompareModal', {flight: item})
    },
    compareAll (data) {
      this.$emit('compareAll', data)
    },
    sortResults (results, sortingType) {
      const validatedResults = results.filter(item => item && item.uniqueId)
      const sortLimit = this.initialRenderResults * this.page

      const recommended = validatedResults
        .sort((a, b) => a.stops === b.stops ? a.minPrice - b.minPrice : a.stops - b.stops)
        .slice(0, sortLimit)

      const fastest = validatedResults.sort((a, b) => a.duration - b.duration).slice(0, sortLimit)

      const cheapest = validatedResults.sort((a, b) => a.minPrice - b.minPrice).slice(0, sortLimit)

      this.$store.commit('setSortResults', {
        recommended,
        fastest,
        cheapest
      })

      // Return results based on sorting type
      switch (sortingType) {
        case 'recommended':
          return recommended.concat(results.filter(item => !recommended.some(rec => rec.uniqueId === item.uniqueId)))
        case 'fastest':
          return fastest.concat(results.filter(item => !fastest.some(rec => rec.uniqueId === item.uniqueId)))
        case 'cheapest':
          return cheapest.concat(results.filter(item => !cheapest.some(rec => rec.uniqueId === item.uniqueId)))
        default:
          return results
      }
    }
  }
}
