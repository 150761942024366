import { render, staticRenderFns } from "./FlightsSearchForm.vue?vue&type=template&id=207a29a0"
import script from "./FlightsSearchForm.vue?vue&type=script&lang=js"
export * from "./FlightsSearchForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AdsMultiCity: require('/home/forge/farescraper.com/components/AdsMultiCity.vue').default,InputAirport: require('/home/forge/farescraper.com/components/InputAirport.vue').default,InputDate: require('/home/forge/farescraper.com/components/InputDate.vue').default,InputPassengers: require('/home/forge/farescraper.com/components/InputPassengers.vue').default,InputCabinClass: require('/home/forge/farescraper.com/components/InputCabinClass.vue').default,IconsTakeOff: require('/home/forge/farescraper.com/components/IconsTakeOff.vue').default})
